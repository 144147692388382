import React, { Suspense, FC } from 'react';
import { useTranslation } from 'react-i18next';

import Bg from './../../images/company/values-bg.png';
import { Content, Title, Subtitle } from './style';

interface Props {}

const PageNotFound: FC<Props> = () => {
  const { t } = useTranslation();

  return (
    <Suspense fallback={<div />}>
      <Content>
        <div className="container">
          <Title>{t('NotFoundMainTitle')}</Title>
          <Subtitle>{t('NotFoundMainSubtitle')}</Subtitle>
          <img src={Bg} alt="bg" />
        </div>
      </Content>
    </Suspense>
  );
};

export default PageNotFound;
