import styled from 'styled-components';

export const Content = styled.div`
  .container {
    position: relative;
    padding: 0 20px;
    height: 680px;
    img {
      object-fit: cover;
      position: absolute;
      top: 0;
      bottom: 0;
      width: 210%;
      left: -210px;
      @media (min-width: 768px) {
        width: 100%;
        left: 0;
      }
    }
    @media (min-width: 992px) {
      min-height: 920px;
    }
  }
`;

export const Title = styled.h1`
  color: var(--black);
  font-family: Rubik;
  font-size: 32px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 40px;
  text-align: center;
  margin-top: 180px;
  @media (min-width: 310px) {
    margin-top: 160px;
  }
  @media (min-width: 385px) {
    margin-top: 140px;
  }
  @media (min-width: 660px) {
    margin-top: 137px;
  }
`;

export const Subtitle = styled.p`
  color: var(--black);
  font-family: Rubik;
  font-size: 24px;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
  margin-top: 18px;
  @media (min-width: 768px) {
    margin-top: 26px;
  }
`;
